<template>
  <div>
    <Header />
    <div class="copyright">
      <div class="copyright-top breadcrumbs d-flex align-items-center">
        <div
          class="container position-relative d-flex flex-column align-items-center"
        >
          <!-- <h2>{{ $t("copyright.copyright_policy") }}</h2> -->
        </div>
      </div>
      <!-- <img src="../../../assets/images/info/copyright-us.jpg" alt="" /> -->
    </div>
    <b-container>
      <b-row>
        <b-col sm="12" md="12">
          <div class="about-block1">
            <div class="container text-center">
              <h1 class="copyright-title h1">
                {{ $t("copyright.copyright_policy") }}
              </h1>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="copyright-block1-img">
        <b-col sm="12" md="12" class="copyright-block1-col">
          <div class="copyright-block1-info">
            <p class="lead">{{ $t("copyright.copyright_main") }}</p>
            <ol class="ml-2">
              <li>
                <strong> {{ $t("copyright.copyright_label1") }}: </strong>
                <div class="inner_list">
                  <ul class="ml-2">
                    <li>
                      <strong>
                        1.1 {{ $t("copyright.copyright_label1_1") }}:
                      </strong>
                      {{ $t("copyright.copyright_p1_p1") }}
                      <ul class="mt-1">
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_1") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_2") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_3") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_4") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_5") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p1_6") }}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        1.2 {{ $t("copyright.copyright_label1_2") }}:
                      </strong>
                      {{ $t("copyright.copyright_p1_p2") }}
                      <ul class="mt-1">
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p2_1") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p2_2") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p2_3") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p2_4") }}
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check" />
                          {{ $t("copyright.copyright_p2_5") }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <strong> {{ $t("copyright.copyright_label2") }}: </strong>
                {{ $t("copyright.copyright_p2") }}
              </li>
              <li>
                <strong> {{ $t("copyright.copyright_label3") }}: </strong>
                {{ $t("copyright.copyright_p3") }}
              </li>
              <li>
                <strong> {{ $t("copyright.copyright_label4") }}: </strong>
                {{ $t("copyright.copyright_p4") }}
                {{ $t("copyright.copyright_p4_1") }}
                <a href="mailto: $t('copyright.copyright_email')">{{
                  $t("copyright.copyright_email")
                }}</a>
              </li>
            </ol>
          </div>
        </b-col>
      </b-row>
      <b-row class="copyright-block-footer mb-5">
        <b-col sm="12" md="12" class="copyright-block1-col">
          <p class="lead">{{ $t("copyright.copyright_footer1") }}</p>
          <p class="lead">{{ $t("copyright.copyright_footer2") }}</p>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormDatepicker,
  BFormTextarea,
  BMedia,
  BImg,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BMedia,
    BImg,
    BCardText,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
};
</script>

<style scoped>
.copyright {
  /* margin-top: 110px; */
  margin-bottom: 75px;

  /* padding: 40px 40px 128px 40px; */
}
.copyright-title {
  font-size: 40px;
  font-weight: 700;
  color: #366ce3;
  margin-bottom: 30px;
}
.copyright-block1-col {
  margin: auto;
}

.copyright .contact-top {
  height: 290px;
}
.copyright .copyright-block1-img img {
  height: 300px;
}

.copyright-block1-img {
  padding: 50px;
}
.copyright-block-footer {
  padding: 0 50px;
}
.copyright .copyright-block1-info {
  margin-top: 50px;
}
.copyright .copyright-block1-info h2 {
  font-size: 25px;
  color: #005692;
}
.copyright .copyright-block1-info p {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}

@media (max-width: 550px) {
  .copyright .copyright-top img {
    width: 330px;
  }
  .copyright .copyright-block1-img img {
    display: none;
  }
  .copyright .copyright-block1-info p {
    text-align: start;
  }
}

.copyright-block2 {
  margin-top: 60px;
}
.copyright-block2 h2 {
  text-align: center;
  font-weight: 500;
  color: #005692;
  margin-bottom: 45px;
}

[dir] .card-img,
[dir] .card-img-top {
  height: 250px;
}
[dir] .card .card-title {
  color: #366ce3;
  font-weight: 500;
  cursor: pointer;
}
[dir] .card-text:last-child {
  font-size: 14px;
}
@media (max-width: 550px) {
  .block-cvshot .card {
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    align-content: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .block-cvshot img {
    margin: 0 7px;
    width: 350px;
  }
}
@media (min-width: 920px) {
  .block-cvshot .card .card-title {
    color: #366ce3;
    font-weight: 600;
    font-size: 22px;
  }
  .block-cvshot .card-text:last-child {
    font-size: 15px;
  }
}
@media (min-width: 920px) {
  [dir="ltr"] .card-body {
    padding: 1.5rem;
    min-height: 350px;
  }
  .cvshots-card .card-body {
    min-height: auto;
    margin: auto;
  }
}
.card-text:last-child {
  font-size: 16px;
  font-weight: 500;
}

.copyright-top {
  background-image: url(../../../assets/images/info/copyright_policy.png);
  padding: 140px 0 60px 0;
  min-height: 60vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.copyright-block1-info ol {
  list-style: none;
  padding: 0;
}

.copyright-block1-info ol li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
}
.copyright-block1-info ol li i {
  font-size: 16px;
  color: #56b8e6;
}

.copyright-block1-info ul {
  list-style: none;
  padding: 0;
}

.copyright-block1-info ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
}
.copyright-block1-info ul li i {
  font-size: 16px;
  color: #56b8e6;
}
.blockchainImg {
  width: 450px;
}
@media (max-width: 550px) {
  .blockchainImg {
    display: none;
    /* width: 400px; */
  }
  .copyright-block2 h2 {
    font-size: 18px;
  }
}
</style>
<style scoped>
@media (min-width: 920px) {
  [dir="rtl"] .card-body {
    padding: 1.5rem;
    min-height: 250px;
  }
}
</style>
